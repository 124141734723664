.about-hr {
  border-top: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 175, 149, 0),
    rgba(0, 175, 149, 0.4) 50%,
    rgba(0, 175, 149, 0)
  );
  height: 2px;
  margin-top: 50px;
  margin-bottom: 90px;
}

.video-preview {
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  width: 995px;
  height: 564px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.play-button {
  width: 165px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(235, 235, 245, 0.6);
  border-radius: 100%;
  margin-right: 10px;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.key-features .feature-card {
  background-color: #272729;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  max-width: 320px;
}

.feature-card {
  background-color: #272729;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  max-width: 320px;
}

.enjoying-memod {
  @apply bg-memod-base;
  border-radius: 22px;
  padding: 30px;
}
.enjoying-memod.bordered {
  border: 1px solid #3a3a3c;
}
.enjoying-memod .major {
  list-style: none;
  padding: 0;
}
.enjoying-memod__title {
  font-family: "Zilla Slab";
  font-style: italic;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: #ffffff;
}
.enjoying-memod__list {
  list-style: none;
  padding: 0;
}
.enjoying-memod__sub {
  font-family: "Gibson";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.enjoying-memod__sign-up {
  font-family: "Gibson";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.enjoying-memod__button {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.enjoying-memod__sign,
.enjoying-memod__list__item {
  font-family: "Gibson";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.enjoying-memod .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.enjoying-memod .content .nav-bar-auth {
  position: relative;
  width: fit-content;
  top: initial;
  right: initial;
  z-index: 9;
}
@media (max-width: 767px) {
  .enjoying-memod .content {
    flex-direction: column;
  }
  .enjoying-memod .content .nav-bar-auth {
    margin: 0 auto;
  }
}

.container {
  @apply !border-none;
}

.body-text,
.body-text-emphasized,
.body-text-sb,
.key-features .feature-card .feature-card-body p {
  font-size: 1.31em;
}
.body-text-sb {
  font-weight: 600;
}
.body-text-emphasized {
  font-style: italic;
}
.body-text-secondary,
.body-text-secondary-sb {
  font-size: 1.19em;
}
.body-text-secondary-sb,
.giant-title {
  font-weight: 600;
}
.giant-title {
  font-family: "Zilla Slab", serif;
  font-size: 6.81em;
  line-height: 82px;
  font-style: italic;
}
.h0 {
  font-size: 3.5em;
  font-weight: 400;
}
.h3,
h3 {
  font-size: 1.63em;
}
.h2,
.h3,
h2,
h3 {
  font-weight: 400;
  margin-bottom: 0;
}
.h2,
h2 {
  font-size: 1.81em;
}
.h1,
h1 {
  font-size: 2.13em;
  font-weight: 400;
  margin-bottom: 0;
}
.key-features {
  padding: 0 0 120px;
}
@media (max-width: 767px) {
  .key-features {
    padding: 30px 0;
  }
}
.key-features h1 {
  margin-bottom: 65px;
  text-align: center;
  color: #05a88f;
}
@media (max-width: 1200px) {
  .key-features h1 {
    text-align: center;
  }
}
.key-features .feature-card {
  background-color: #272729;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  max-width: 320px;
}
@media (max-width: 1200px) {
  .key-features .feature-card {
    margin: 0 auto;
  }
}
.key-features .feature-card figure {
  height: 200px;
  display: flex;
  align-items: center;
}
.key-features .feature-card figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.key-features .feature-card .feature-card-body {
  padding: 15px 25px 25px;
}
.key-features .feature-card .feature-card-body h2 {
  color: #05a88f;
  margin-bottom: 10px;
}
.key-features .feature-card .feature-card-body p {
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .who-we-are {
    max-width: 75%;
    margin: 0 auto;
  }
}
.who-we-are h1 {
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .who-we-are h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.who-we-are p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin-bottom: 65px;
  color: #b5b5b5;
}
.who-we-are .collaborator-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(44, 44, 46, 0.9);
  border: 1px solid rgba(112, 112, 112, 0.2);
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 45px;
}
@media (max-width: 576px) {
  .who-we-are .collaborator-card {
    max-width: 235px;
    margin: 0 auto;
    margin-bottom: 45px;
  }
}
.who-we-are .collaborator-card figure {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}
.who-we-are .collaborator-card figure img {
  width: 100%;
}
.who-we-are .collaborator-card p {
  font-size: 17px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #f2f2f2;
  margin-bottom: 15px;
}
.who-we-are .collaborator-card a {
  padding: 2px 20px;
}

.feature /deep/ h1 {
  color: white;
  background: transparent;
}
.feature /deep/ .row {
  flex-direction: row-reverse;
}

@media (max-width: 991px) {
  .what-is-memod .video-preview {
    width: 665px;
    height: 375px;
  }
}

@media (max-width: 767px) {
  .what-is-memod .video-preview {
    width: 546px;
    height: 320px;
  }
}

@media (max-width: 576px) {
  .what-is-memod .video-preview {
    width: 100%;
    height: 400px;
    background-position: 0;
    margin-bottom: 30px;
  }
}

.what-is-memod .video-preview .play-button {
  width: 165px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.what-is-memod .video-preview .play-button .play-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(235, 235, 245, 0.6);
  border-radius: 100%;
  margin-right: 10px;
}

.what-is-memod .video-preview .play-button .play-icon img {
  margin-left: 4px;
}

.feature {
  margin-bottom: 200px;
  pointer-events: none;
}
.feature h1 {
  text-align: center;
  /* line-height: 100px; */
  margin-bottom: 50px;
  color: #05a88f;
}
@media (max-width: 1200px) {
  .feature h1 {
    text-align: center;
  }
}
.feature .subtitle {
  z-index: 2;
  position: relative;
}
.feature ul {
  list-style: none;
}
.feature ul li {
  position: relative;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 20px;
}

.feature ul li::before {
  content: "";
  position: absolute;
  top: 10px;
  width: 22px;
  height: 22px;
  background-image: url("https://v2.memod.com/_nuxt/img/bullet.dcc537d.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -40px;
  border-radius: 100%;
}

.feature ul li:last-child {
  margin-bottom: 0;
}
.feature ul li a {
  pointer-events: initial;
  color: #05a88f;
}
.feature img {
  border-radius: 12px;
}
@media (max-width: 1200px) {
  .feature {
    margin-bottom: 0;
    pointer-events: initial;
  }
  .feature .row > .col {
    padding-left: 0;
    padding-right: 0;
  }
  .feature .feature-info {
    max-height: 90px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.5s;
  }
  .feature .feature-info h1 {
    position: relative;
    margin-bottom: 0;
    margin-left: 0 !important;
    font-size: 24px;
    line-height: 27px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 2;
  }
  .feature .feature-info ul {
    position: relative;
    z-index: 2;
    padding-right: 30px;
    padding-left: 60px;
  }
  .feature .feature-info ul li {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  .feature .feature-info ul li::before {
    top: 3px;
  }
  .feature .feature-info ul li:last-child {
    margin-bottom: 30px;
  }
  .feature .feature-info ul li.hide-on-mobile {
    display: none;
  }
  .feature .feature-info img {
    position: absolute;
    transform: translateY(-50%);
    transition: transform 0.5s;
    top: 0;
    margin-left: 0 !important;
    border-radius: 0;
    z-index: 1;
    width: 100%;
  }
  .feature .feature-info.open {
    min-height: 550px;
    max-height: 1600px;
  }
  .feature .feature-info.open img {
    transform: translateY(30%) scale(2);
  }
}
.feature .subtitle {
  font-size: 18px;
  line-height: 38px;
  margin-bottom: 40px;
  margin-left: 40px;
}

.more-features /deep/ .feature:nth-child(even) .row {
  flex-direction: row-reverse;
}
.more-features /deep/ .feature:nth-child(even) .row h1 {
  margin-left: 40px;
}
.more-features /deep/ .feature:nth-child(even) .row ul {
  margin-left: 0;
}
.more-features /deep/ .feature:nth-child(even) .row img {
  margin-left: 0;
  margin-right: 40px;
}

.about-us {
  padding-top: 2.5rem;
}

.about-us hr {
  border-top: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 175, 149, 0) 0%,
    rgba(0, 175, 149, 0.4) 50%,
    rgba(0, 175, 149, 0) 100%
  );
  height: 2px;
  margin-top: 50px;
  margin-bottom: 90px;
}

@media (min-width: 1200px) {
  .about-us .who-we-are {
    max-width: 75%;
    margin: 0 auto;
  }
}

.about-us .who-we-are h1 {
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .about-us .who-we-are h1 {
    font-size: 24px;
    line-height: 30px;
  }
}

.about-us .who-we-are p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin-bottom: 65px;
  color: #b5b5b5;
}

.about-us .who-we-are .collaborator-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(44, 44, 46, 0.9);
  border: 1px solid rgba(112, 112, 112, 0.2);
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 45px;
}

@media (max-width: 576px) {
  .about-us .who-we-are .collaborator-card {
    max-width: 235px;
    margin: 0 auto;
    margin-bottom: 45px;
  }
}

.about-us .who-we-are .collaborator-card figure {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}

.about-us .who-we-are .collaborator-card figure img {
  width: 100%;
}

.about-us .who-we-are .collaborator-card p {
  font-size: 17px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #f2f2f2;
  margin-bottom: 15px;
}

.about-us .who-we-are .collaborator-card a {
  padding: 2px 20px;
}

.about-us .feature /deep/ h1 {
  color: white;
  background: transparent;
}

.about-us .feature /deep/ .row {
  flex-direction: row-reverse;
}

.about-us .memos-on-memod {
  margin-bottom: 150px;
}

.about-us .memos-on-memod .row .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.about-us .memos-on-memod h1 {
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .about-us .memos-on-memod h1 {
    font-size: 24px;
    line-height: 30px;
  }
}

.about-us .memos-on-memod p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin-bottom: 65px;
  color: #b5b5b5;
}

.about-us .memos-on-memod .memod-card {
  width: 260px;
  margin: 0 auto;
  margin-bottom: 25px;
}
