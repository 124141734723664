@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@composer/index.css";
@import "tippy.js/dist/tippy.css";
@import "./legacy.css";
@import "react-toastify/dist/ReactToastify.css";

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

:root {
  /**
   * React Toastify Variables
   */
  --toastify-toast-width: 27rem;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.testimonial-primary {
  background: linear-gradient(
    90deg,
    rgba(0, 175, 149, 0),
    rgba(0, 175, 149, 0.4) 50%,
    rgba(0, 175, 149, 0)
  );
  border-top: 0;
  height: 0.125rem;
  margin-top: 7.5rem;
  margin-bottom: 2.6885rem;
}

.testimonial-secondary {
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 43.9%, 0),
    hsla(0, 0%, 43.9%, 0.2) 50%,
    hsla(0, 0%, 43.9%, 0)
  );
  background-image: linear-gradient(
    90deg,
    rgba(112, 112, 112, 0),
    rgba(112, 112, 112, 0.2) 50%,
    rgba(112, 112, 112, 0)
  );
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  border-top: 0;
  height: 0.125rem;
  margin-top: 7.5rem;
  margin-bottom: 2.6885rem;
}

@font-face {
  font-family: "gibson";
  src: url("~@assets/fonts/gibson-regular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "gibson";
  src: url("~@assets/fonts/gibson-italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "gibson";
  src: url("~@assets/fonts/gibson-medium.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "gibson";
  src: url("~@assets/fonts/gibson-semibolditalic.otf") format("opentype");
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: "gibson-light";
  src: url("~@assets/fonts/gibson-light.woff2");
}
@font-face {
  font-family: "ZillaSlab";
  src: url("~@assets/fonts/ZillaSlab-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}

* {
  @apply font-gibson antialiased;
}

a[rel*="noopener"] {
  @apply !text-memod-green-base;
}

.legal-container {
  position: relative;
  z-index: 2;
  background-color: #181818;

  &::before {
    content: "";
    height: 100vh;
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: -2px;
    left: 0;
    right: 0;
    opacity: 1;
    background: rgb(24, 24, 24);
    background: linear-gradient(
      0deg,
      rgba(24, 24, 24, 1) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    animation-name: heroOpacity;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: -2;
    background-image: url("./../../public/img/legal/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.container {
  @apply flex h-full w-full border-memod-separator px-2 py-10 md:px-[74px] md:py-13 xl:border-r xl:px-4;
}

.aside {
  @apply flex  min-h-screen flex-col items-center justify-start border-0 xl:w-[36%] xl:!max-w-[36%];
}

.bullet * {
  @apply !bg-transparent !text-inherit !text-white;
  margin: 0 !important;
  line-height: inherit !important;
}

.memo-parts {
  padding: 12px 10px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.04),
      rgba(255, 255, 255, 0.04)
    ),
    #1c1c1e;
  border-radius: 8px;
}

.publish-image {
  background: linear-gradient(
      180deg,
      rgba(28, 28, 30, 0.2) 34.38%,
      rgba(28, 28, 30, 0.6) 66.37%,
      #1c1c1e 100%
    ),
    rgba(166, 166, 166, 0.2);
}

.multiselectContainer {
  border: none !important;
}

.searchBox {
  @apply flex w-full items-end border-b border-solid border-b-[rgba(235,235,245,0.3)] text-lg leading-7 text-[rgba(235,235,245,0.3)];

  border-bottom: 1px solid rgba(235, 235, 245, 0.3) !important;
}

.searchBox::placeholder {
  @apply text-[rgba(235,235,245,0.3)];
}

.inputField {
  @apply border-b border-solid border-[none] border-b-[rgba(235,235,245,0.5)];
}

.chip {
  @apply mb-[5px] mr-2.5 flex h-[30px] items-center rounded bg-memod-button px-2.5 py-0 text-lg text-memod-label;

  border-radius: 4px !important;
  background-color: #2c2c2e !important;
}

.searchWrapper {
  border: none !important;
}

.optionContainer {
  @apply bg-[#1b1b1b];
  border: none !important;
}

.option {
  @apply bg-memod-button text-[white];
}

.form-group {
  @apply relative mb-[35px];
}

.form-group label {
  @apply m-0 mb-2.5 font-[normal] text-sm text-memod-white-base;
  font-family: "gibson";
}

.form-group label span {
  @apply lowercase;
}

.form-control {
  @apply h-[initial] rounded-none border-0 border-b border-solid border-b-[rgba(235,235,245,0.3)] bg-transparent p-0 text-lg text-[white] shadow-none focus:border-composer-default;
}

.form-control[disabled] {
  @apply opacity-50;
}

.form-control::placeholder {
  @apply text-[rgba(235,235,245,0.3)];
}

.read-next-card {
  @apply my-2 flex h-full !w-full cursor-pointer items-center space-x-1 rounded border-memod-separator p-1 transition ease-in-out hover:border hover:bg-[#262626];
}

.aspect-16-9 {
  aspect-ratio: 16 / 9;
  object-fit: contain;
  @apply rounded;
}

.arrow-up {
  border-width: 0 10px 10px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -7px;
  left: calc(50% - 11px);
  margin-top: 0;
  margin-bottom: 0;
  border-color: #2c2c2e;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 3px;
  border-color: #2c2c2e;
  z-index: 1;
}

.button-report-container {
  @apply mt-[3px] flex flex-row items-center space-x-3 rounded bg-memod-button px-3 py-2 text-base text-memod-reader-text hover:text-base-white;
}

.button-report-container:hover .flag {
  @apply hover:fill-base-white;
}

.form-field {
  @apply flex flex-col gap-2 text-memod-white-base;
}

.form-input {
  @apply w-full select-none border-b border-white/30 !bg-transparent  text-base leading-7 shadow-none outline-none;
}

.form-input:focus {
  @apply border-memod-green-base;
}

.form-input.error {
  @apply !border-memod-error-base;
}

.form-input-eye {
  @apply border-b border-white/30  focus:border-memod-green-base;
}

.form-input:focus + .form-input-eye {
  @apply border-memod-green-base;
}

.form-input.error + .form-input-eye {
  @apply !border-memod-error-base;
}

.characters-counter {
  font-size: 12px;
  color: rgba(235, 235, 245, 0.3);
  margin-left: auto;
  display: none;
}

.text-area:focus {
  .text-label {
    display: flex;
    flex-direction: justify-end;
  }
}

.form-input:focus {
  .characters-counter {
    display: block;
  }
}
.sign-in-button {
  @apply flex h-11 w-full items-center gap-2.5 rounded-full p-1.5 text-sm;
}

.sign-in-button .divider {
  @apply flex h-full min-w-[47.5px] items-center justify-center border-r border-r-[#1c1c1c1a];
}

.link-green {
  @apply cursor-pointer text-memod-green-base hover:underline;
}

/* Change the white to any color https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
}
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
}

input[type="radio"] {
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.bg-banner-lock {
  background: linear-gradient(
      0deg,
      rgba(5, 168, 143, 0.04),
      rgba(5, 168, 143, 0.04)
    ),
    rgba(49, 49, 49, 0.9);
  backdrop-filter: blur(40px);
}

.ReactModal__Overlay {
  z-index: 10;
}

.bullet p {
  @apply !break-keep;
  word-wrap: break-word;
}

.bullet > span > p {
  width: auto;
  word-wrap: break-word;
}

.bullet > p > a {
  @apply !line-clamp-1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ReactModal__Body--open {
  overflow: hidden;
}

/**
 * React Toastify Styles
 */
.Toastify__toast-icon {
  width: 1.875rem; /* 30px@16px */
}

.mansory_grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(274px, 1fr));
  grid-template-rows: masonry;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.my-masonry-grid_column {
  padding-left: 0px;
  /* gutter size */
  background-clip: padding-box;
  @media only screen and (min-width: 700px) {
    padding-left: 20px;
  }
}

/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: 20px;
}

.memo-feed {
  z-index: 1;
  animation: show;
  animation-timeline: view();
  animation-range: entry;
}

@keyframes show {
  33.33% {
    translate: 0 30%;
  }
}

.norows {
  width: 100% !important;
  display: flex;
  flex-direction: row !important;
  row-gap: 20px !important;
}

/* #embedded-link {
  @apply p flex !bg-memod-button;

  #image {
    @apply !mr-2 w-1/6;
  }

  #body {
    @apply w-5/6;

    #url {
      img {
        @apply hidden;
      }

      span {
        @apply hidden;
      }
    }
  }

  #footer {
    @apply flex flex-col;
  }
} */

#embedded-link {
  @apply mb-5 flex h-[113px] w-fit flex-row items-center gap-4 overflow-hidden rounded-xl bg-[#292929] p-3;

  #image {
    @apply h-[63px] w-[120px] rounded bg-contain;

    img {
      @apply overflow-hidden rounded bg-contain;
    }
  }

  #body {
    @apply flex flex-col;

    #url {
      @apply flex w-40 items-center break-words text-xs sm:w-52 md:w-80 lg:w-96;

      img {
        @apply !mr-2 h-5 w-5;
      }

      span {
        @apply truncate text-base text-xs font-semibold text-[#D7D9DB];
        text-overflow: ellipsis !important;
      }
    }
  }

  #footer {
    @apply flex flex-col;

    a {
      @apply text-xs font-semibold text-[#05A88F] lg:text-sm;
    }

    span {
      @apply overflow-hidden text-xs text-[#D7D9DB] lg:text-sm;
      text-overflow: ellipsis !important;
    }
  }
}

.break-word {
  word-break: break-word;
}

.publish-memo-modal .ReactModal__Content {
  scrollbar-width: 2px;
}

.publish-memo-modal .ReactModal__Content::-webkit-scrollbar {
  width: 2px;
}
