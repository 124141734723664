*::-webkit-scrollbar {
  @apply w-2 bg-memod-separator;
}

*::-webkit-scrollbar-thumb {
  @apply bg-memod-separator;
  background: #05a88f;
}

* {
  caret-color: #05a88f;
}

.popover-bar::-webkit-scrollbar {
  @apply w-2 !rounded bg-[#2d2e2e];
}

.popover-bar::-webkit-scrollbar-thumb {
  @apply rounded bg-memod-separator;
  background: #979797;
}

.popover-bar {
  caret-color: #c09815;
}

.popover-bar {
  position: relative;
}

.save-popover:first-child {
  background-color: #2d2e2e;
  position: sticky;
  top: 0.1px;
  @apply border-b border-[#3A3A3C];
}

.save-popover:nth-child(2) {
  /* margin-top: 30px; */
}

.scroll-mini::-webkit-scrollbar {
  @apply w-1 rounded bg-memod-separator/60;
}

.scroll-mini::-webkit-scrollbar-thumb {
  @apply !h-[1px] rounded bg-memod-grey-primary;
}

.scroll-mini {
  caret-color: #db720a;
}

.bullet-text-editor {
  @apply rounded-lg border border-solid border-[rgba(84,84,88,0.65)];
  @apply ml-auto flex flex-col py-[16px] pl-[38px] pr-[42px];
}

.default-save-button {
  @apply rounded-[30px] text-[rgba(0,0,0,0.4)];
  @apply flex items-center justify-center px-[14px] py-2 text-sm leading-4 outline-none;
  background: rgba(255, 255, 255, 0.4);
}

.active-save-button {
  @apply rounded-[30px] text-base-black;
  @apply flex items-center justify-center px-[14px] py-2 text-sm leading-4 outline-none;
  background: #ffffff;
}

.cancel-button {
  @apply rounded-[30px] border border-solid border-base-white text-base-white;
  @apply flex items-center justify-center px-[14px] py-2 text-sm leading-4 outline-none;
  background: transparent;
}

.characters-count {
  @apply text-[rgba(235,235,245,0.6)];
  @apply text-xs md:text-sm;
}

.memo-title {
  @apply mb-6 w-full border-0 border-b border-solid border-b-[rgba(84,84,88,0.65)] px-0 pb-[7px] text-2xl font-semibold not-italic leading-8 tracking-wide text-white transition-[border-color] duration-[0.2s];
  font-family: "gibson";
  outline: 0;
  background: transparent;
}

.bullet {
  @apply relative mb-[5px] flex w-full gap-3 p-2 text-xl font-normal not-italic leading-8 text-[rgba(235,235,245,0.6)] transition-[ease-out] duration-[0.3s] hover:rounded;
  font-family: "gibson";
}

.bullet > span > p {
  width: auto;
  word-wrap: break-word;
}

.bullet * {
  @apply mt-0 box-border;
}

.bullet:hover {
  /* background: #2c2c2e; */
}

.bullets {
  @apply relative;
}

.bullets:hover .bullet .bullets-count {
  z-index: -1;
}

.bullets:hover .bullet {
  @apply rounded;
  /* background: #2c2c2e; */
}

.bullets:hover .bullet-actions {
  @apply z-[100] flex;
}

.bullet p {
  @apply mb-2 mt-0 !break-keep;
}

.bullet > span {
  @apply !break-keep;
}

.bullet ul p,
.bullet ol p {
  @apply mb-2.5;
}

.bullet span {
  @apply w-full;
}

blockquote {
  @apply mx-0 border-l-2 border-solid border-l-[#ddd] pl-2.5 text-["#aaa"];
}

.bullet-icon {
  @apply rounded-[100%];
}

.bullets-count {
  @apply block text-right text-xs leading-4 tracking-[0.02em] text-[rgba(235,235,245,0.6)] transition-all delay-[0.2s] duration-[ease-out];
}

.bullet-actions {
  @apply absolute -top-2 right-[18px] hidden items-center justify-between gap-1.5 rounded border border-solid border-memod-separator p-1 transition-all delay-[0.2s] duration-[ease-out];
  background: #1c1c1e;
}

.bullet-actions-icon {
  @apply flex h-6 w-6 items-center justify-center p-0.5 transition-all delay-[0.2s] duration-[ease-out] hover:rounded;
}

.bullet-actions-icon:hover {
  background: #2c2c2e;
}

.editor-link-card {
  @apply flex min-h-[100px] overflow-hidden rounded-[10px] bg-memod-button leading-[1.31em] text-memod-white-base no-underline;
  font-family: "gibson";
}

.card-container {
  @apply m-0 box-border flex w-[70%] flex-col justify-center truncate whitespace-pre-wrap bg-transparent p-[15px] text-left font-normal leading-[inherit] tracking-[0.25px] text-[inherit] text-memod-white-base;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-family: "gibson";
  word-break: break-word;
}

.editor-link-card .card-container .web-link {
  @apply flex items-center overflow-hidden text-ellipsis whitespace-nowrap text-sm text-[rgba(235,235,245,0.6)];
}

.editor-link-card .card-image {
  @apply flex w-[30%] items-center justify-center overflow-hidden bg-[#242425];
  height: 200px;
}

.editor-link-card .card-image img {
  @apply h-full w-full object-cover;
}

.editor-link-card .card-container .favicon-and-name-container {
  @apply mb-[5px] flex items-center;
}

.editor-link-card .card-container .favicon-and-name-container .favicon {
  @apply mr-1.5 w-5;
}

.editor-link-card .card-container .card-description {
  @apply mb-[5px] block overflow-hidden truncate text-sm;
}

.memo-link-card .card-container .card-description {
  @apply whitespace-pre-line text-right;
  word-break: break-word;
}

#bullet-link {
  @apply hidden;
}

img {
  aspect-ratio: 16/9;
  object-fit: cover;
  width: 712px;
  height: 412px;
}

.collections-img {
  aspect-ratio: 4/3;
  width: 100.17;
  height: 68px;
  @media screen and (min-width: 700px) {
    width: 177.8;
    height: 116px;
  }
}

.SortableList {
  @apply flex list-none flex-col gap-2.5 p-0;
}
.SortableItem {
  @apply list-none rounded-[calc(4px_/_var(--scale-x,1))] shadow-[0_0_0_calc(1px_/_var(--scale-x,1))_rgba(63,63,68,0.05),0_1px_calc(3px_/_var(--scale-x,1))_0_rgba(34,33,81,0.15)];
}
.DragHandle:hover {
  @apply bg-[rgba(0,0,0,0.05)];
}
.DragHandle:focus-visible {
  @apply shadow-[0_0px_0px_2px_#4c9ffe];
}

.img-container {
  aspect-ratio: auto;
  object-fit: cover;
  width: auto;
  height: auto;
}

.bullet {
  span {
    p {
      a {
        @apply line-clamp-1 max-w-[800px];
      }
    }
  }
}
